export const homePage = {
  _id: "6470ac674d02dd1d7364385a",
  descriptionBelowFirstSlider: {
    se: "<p>Vi vill g&auml;rna h&ouml;ra om de utmaningar ni har i just er v&aring;rdverksamhet och se hur v&aring;r digitala v&aring;rdplattform kan effektivisera era processer, spara tid och ge era patienter en n&auml;rmare v&aring;rd. Vi ses g&auml;rna fysiskt i Stockholmsomr&aring;det eller s&aring; budar vi &ouml;ver en lunch och tar m&ouml;tet &ouml;ver video!</p>",
    en: "<p>Vi vill g&auml;rna h&ouml;ra om de utmaningar ni har i just er v&aring;rdverksamhet och se hur v&aring;r digitala v&aring;rdplattform kan effektivisera dina processer, spara tid och ge era patienter en n&auml;rmare v&aring;rd.Vi ses g&auml;rna fysiskt i Stockholmsomr&aring;det eller s&aring; budar vi &ouml;ver en lunch och tar m&ouml;tet &ouml;ver video. Vi bjuder!</p>",
  },
  tsCreated: 1709543098,
  firstSliderItems: [
    {
      title: {
        se: "Nå den fulla potentialen av digifysisk vård med en plattform som ger er flow",
        en: "Unlock the power of digital healthcare with a platform that flows ",
      },
      description: {
        se: "<h5>M&ouml;t framtidens sjukv&aring;rd med Curoflow - en helt&auml;ckande digital v&aring;rdplattform f&ouml;r alla typer av v&aring;rdgivare</h5>",
        en: "<h5>Say hello to the future of healthcare with Curoflow - the comprehensive digital healthcare and telemedicine platform</h5>",
      },
      link: "",
      imageOrVideo:
        "/uploads/Nurse_working_with_Curoflow_telemedicine_platform_at_primary_care_clinic.jpg",
      edit: 0,
      imageOrVideoMobile:
        "/uploads/Digital_healthcare_through_Curoflow_white_label_telemedicine_platform.jpg",
    },
    {
      title: {
        se: "Öka bekvämligheten genom att låta patienter nå er mottagning online",
        en: "Increase convenience by enabling patients to reach your clinic online",
      },
      description: {
        se: "<h5>Tillg&auml;nglig v&aring;rd f&ouml;r era patienter med chatt, video, medicinska formul&auml;r, bokningsbara kalendrar, recept, resultat och s&aring; mycket mer. Ni best&auml;mmer!</h5>",
        en: "<h5>Easy access for your patients with chat, video, medical forms, bookable calendars, prescriptions, results and so much more. You decide!</h5>",
      },
      imageOrVideo:
        "/uploads/Digital_healthcare_through_Curoflow_telemedicine_platform.jpg",
      link: "var-plattform/digital-patientportal-i-vardplattformen",
      edit: 1,
      imageOrVideoMobile:
        "/uploads/Digital_healthcare_through_the_Curoflow_telemedicine_platform.jpg",
    },
    {
      title: {
        se: "Curoflow digital vårdplattform är valet för GDPR-efterlevnad",
        en: "Curoflow telemedicine platform is the choice for GDPR-compliance",
      },
      description: {
        se: "<h5>Plattformen &auml;r CE-m&auml;rkt enligt MDR och vi behandlar all data inom EU f&ouml;r strikt efterlevnad av GDPR</h5>",
        en: "<h5>The platform is CE-marked according to the MDR and we process all data within the EU for strict compliance with the GDPR</h5>",
      },
      link: "gdpr-efterlevnad-i-Curoflow-digital-vardplattform",
      imageOrVideo:
        "/uploads/Providing_digital_healthcare_through_the_GDPR-secure_telemedicine_platform.jpg",
      edit: 2,
      imageOrVideoMobile:
        "/uploads/Providing_digital_healthcare_through_a_GDPR-secure_telemedicine_software.jpg",
    },
    {
      title: {
        se: "Vill ni ha fler och mer nöjda patienter på er vårdcentral?",
        en: "Do you want more and happier patients at your primary care clinic?",
      },
      description: {
        se: "<h5>Curoflow v&aring;rdplattform revolutionerar prim&auml;rv&aring;rden med digitala l&ouml;sningar som hyllas av b&aring;de v&aring;rdpersonal och patienter</h5>",
        en: "<h5>The Curoflow telemedicine platform is revolutionizing primary care with digital solutions praised by both healthcare professionals and patients</h5>",
      },
      imageOrVideo:
        "/uploads/Nurse_using_Curoflow_telemedicine_platform_at_primary_care_clinic.jpg",
      link: "vi-hjalper/digital-vardplattform-for-primarvard",
      edit: 3,
      imageOrVideoMobile:
        "/uploads/Nurse_using_the_Curoflow_telemedicine_software_at_primary_care_clinic.jpg",
    },
    {
      title: {
        se: "En digital vårdplattform för fertilitetsvården",
        en: "A telemedicine platform for fertility and reproductive healthcare",
      },
      description: {
        se: "<h5>Enkel kommunikation, minskade ledtider och kalendrar med automatiska p&aring;minnelser - Curoflow &ouml;kar chanserna f&ouml;r lyckade fertilitetsbehandlingar</h5>",
        en: "<h5>Easy communication, reduced lead times and calendars with automatic reminders - Curoflow increases the chances of successful fertility treatments</h5>",
      },
      imageOrVideo:
        "/uploads/Nurse_treating_patients_online_through_Curoflow_telemedicine_platform.jpg",
      link: "vi-hjalper/digital-vardplattform-for-fertilitetsvard",
      edit: 4,
      imageOrVideoMobile:
        "/uploads/Nurse_treating_patients_online_through_the_Curoflow_telemedicine_platform.jpg",
    },
  ],
  secondSliderITems: [
    {
      title: {
        en: "Digital entry points for your patients",
        se: "Digitala ingångar för era patienter",
      },
      edit: 0,
      imageOrVideo:
        "/uploads/Digital-patient-portal-in-web-and-app.webp",
      link: "/en/our-solution/digital-patient-portal-in-practice-management-software",
      tabname: { se: "Digital patientportal", en: "Digital patient portal" },
      description: {
        se: "<p>Genom Curoflow kan patienter smidigt n&aring; er mottagning online f&ouml;r att s&ouml;ka v&aring;rd. Ni har friheten att v&auml;lja patienternas digitala ing&aring;ngar, s&aring;som chattmeddelanden, tidsbokning av digitala eller fysiska bes&ouml;k och m&ouml;jligheten att styra v&auml;xelsamtal digitalt. Dessutom kan ni enkelt boka in och kalla patienter till nya m&ouml;ten, s&aring;v&auml;l digitala som fysiska.</p>",

        en: "<p>Through Curoflow, patients can effortlessly connect with your practice online and seek medical care. You have the freedom to select their digital access points, like chat-based messaging, scheduling digital or in-person visits, and whether they can submit their own care requests. Moreover, you can conveniently create new appointments, including follow-up visits conducted online or in person.</p>",
      },
      btnText: {
        se: "Upptäck er digitala partientportal",
        en: "Discover your digital patient portal ",
      },
      alt: "Digital patient portal for your patients through web interface and mobile app"
    },
    {
      title: {
        en: "Self-service & automation",
        se: "Självbetjäning & automation",
      },
      edit: 1,
      imageOrVideo:
        "/uploads/Patient-using-self-service-features-in-Curoflow-practice-management-software.webp",
      link: "/en/our-solution/digital-patient-portal-in-practice-management-software#section4",
      tabname: {
        se: "Självbetjäning & automation",
        en: "Self-service & automation",
      },
      description: {
        se: "<p>Curoflow minskar b&ouml;rdan f&ouml;r v&aring;rdpersonal genom att automatisera processer och m&ouml;jligg&ouml;ra f&ouml;r patienter att sj&auml;lva g&ouml;ra bokningar, skapa underlag f&ouml;r status och anamnes och utf&ouml;ra andra administrativt tunga uppgifter. Genom integrationer med journalsystem &ouml;verf&ouml;rs kalenderbokningar och journalanteckningar enkelt mellan systemen.</p>",


        en: "<p>Curoflow eases the workload of healthcare professionals by automating tasks and empowering patients to make appointments, complete their medical history, and manage other activities independently. Curoflow seamlessly integrates with EHR systems, automatically transferring calendar bookings and clinical notes between the systems.</p>",
      },
      btnText: {
        se: "Upptäck självbetjäning & automation",
        en: "Discover self-service & automation",
      },
      alt: "Patient using self-service features in Curoflow practice management software for triage and medical history taking"
    },
    {
      title: {
        en: "White-label & customisation",
        se: "White label & anpassning",
      },
      edit: 2,
      imageOrVideo:
        "/uploads/Mobile-apps-and-web-interface-with-white-label-practice-management-software.webp",
      link: "/en/our-solution/white-label-and-customisable-practice-management-software",
      tabname: {
        se: "White label & anpassning",
        en: "White-label & customisation",
      },
      description: {
        se: "<p>Curoflow v&aring;rdplattform &auml;r skalbar och anpassningsbar f&ouml;r olika typer av v&aring;rdorganisationer, fr&aring;n sm&aring; kliniker till hela kommuner, regioner och st&ouml;rre f&ouml;retag. Plattformen erbjuds som white label via webb och app och det &auml;r endast ert varum&auml;rke och grafiska profil som &auml;r synligt f&ouml;r era patienter.&nbsp;</p>",

        en: "<p>The Curoflow practice management software is scalable and adaptable to healthcare organisations of all sizes, ranging from individual GP practices to large enterprises. The software is a white-label product, ensuring that only your brand and visual identity are visible to your patients.</p>",
      },
      btnText: {
        se: "Upptäck white label & anpassning",
        en: "Discover white-label & customisation",
      },
      alt: "Branded mobile apps and desktop with Curoflow white-label practice management software"
    },
    {
      title: { en: "Chat & video meetings", se: "Chatt & videomöten" },
      edit: 3,
      imageOrVideo:
        "/uploads/Doctor-in-GDPR-compliant-video-meeting.webp",
      link: "/en/our-solution/chat-and-video-meetings-in-practice-management-software",
      tabname: { se: "Chatt & videomöten", en: "Chat & video meetings" },
      description: {
        se: "<p>Med Curoflow kan ni enkelt kommunicera med patienter, kollegor och externa parter n&auml;r och var det passar dem och er. V&aring;r videotj&auml;nst &auml;r egenutvecklad f&ouml;r s&auml;kra v&aring;rdm&ouml;ten som f&ouml;ljer GDPR och ni kan enkelt bjuda in tredje part utan n&aring;gra krav p&aring; mjukvaruinstallation eller nedladdningar. I den asynkrona chatten kan ni &auml;ven dela dynamiska och intelligenta fr&aring;geformul&auml;r, bilder och annan relevant information som r&ouml;r v&aring;rd&auml;renden.</p>",

        en: "<p>Curoflow enables you to conveniently connect with your patients and other parties anytime and anywhere that is convenient for them. Our video service is designed for secure healthcare meetings that comply with GDPR regulations. You can effortlessly invite third parties without the need for software installation. In the asynchronous chat, you can share dynamic questionnaires, images, and other relevant information related to medical cases.</p>",
      },
      btnText: {
        se: "Upptäck chatt & videomöten",
        en: "Discover chat & video meetings",
      },
      alt: "Doctor in multi-party and GDPR-compliant video meeting"
    },
    {
      title: {
        en: "Patient monitoring with data",
        se: "Kronikermonitorering med data",
      },
      edit: 4,
      imageOrVideo:
        "/uploads/Patient-tracking-health-data-for-remote-patient-monitoring.webp",
      link: "/en/our-solution/patient-monitoring-with-data-in-practice-management-software",
      tabname: {
        se: "Kronikermonitorering med data",
        en: "Patient monitoring with data",
      },
      description: {
        se: "<p>Curoflows dagboksfunktionalitet &auml;r unik och m&ouml;jligg&ouml;r monitorering av patienter med kroniska tillst&aring;nd. Patienterna f&ouml;ljs upp under angivna intervaller vilket ger er strukturerad information &ouml;ver tid. Informationen omvandlas till data, statistik och beslutsst&ouml;d. Patienter upplever de automatiserade funktionerna som att v&aring;rdgivaren ser och h&ouml;r dem p&aring; regelbunden basis.</p>",

        en: "<p>Curoflow's calendar with a symptom tracker enables remote monitoring of patients with chronic conditions. Patients can log structured health data, which converts into valuable statistics for the healthcare provider. The calendar also offers customisable notifications, making it easier for patients to track their progress in longer care processes.</p>",
      },
      btnText: {
        se: "Upptäck kronikermonitorering med data",
        en: "Discover patient monitoring with data",
      },
      alt: "Patient tracking their health status and metrics for remote patient monitoring"
    },
    {
      tabname: {
        en: "Consultations & referrals",
        se: "Konsultationer & remittering",
      },
      title: {
        en: "Enhancing consultations & referrals",
        se: "Konsultationer & remittering",
      },
      description: {
        en: "<p>Effortlessly share patient cases and information electronically with e-Connect! This tool allows healthcare providers to easily consult peers across different clinics and streamline patient referrals between organisations. By using e-Connect, you will not only increase referral numbers and optimise your operations but also ensure smooth and uninterrupted patient journeys.</p>",

        se: "<p>Dela patientärenden och patientinformation med andra vårdgivare elektroniskt! e-Connect gör det möjligt för vårdgivare att konsultera personal på andra mottagningar samt att sömlöst remittera patienter mellan olika organisationer. Genom e-Connect kan ni inte bara öka antalet remisser och effektivisera era processer, utan även leverera en sömlös vårdupplevelse för era patienter.</p>",
      },
      imageOrVideo:
        "/uploads/Doctor-uses-Curoflow-to-send-and-receive-referrals.webp",
      link: "/en/our-solution/consultations-and-referrals-in-practice-management-software",
      btnText: { en: "Discover e-Connect", se: "Upptäck e-Connect" },
      edit: 6,
      alt: "Doctor uses Curoflow to send and receive patient referrals to other clinics"
    },
    {
      tabname: { se: "Teknik & support", en: "Technology & support" },
      title: { se: "Teknik & support", en: "Technology & support" },
      description: {
        se: "<p>Curoflow plattform är helt utvecklad av oss och vi integrerar ständigt nya journalsystem och kommunikationsverktyg. Den digitala vårdplattformen är CE-märkt i enlighet med MDR och vi behandlar all data inom EU för strikt efterlevnad av GDPR. Vi hjälper alla kunder med onboarding och vårt supportteam är tillgängligt sju dagar i veckan om ni behöver hjälp.</p>",

        en: "<p>The Curoflow system is entirely developed by us giving us complete control over our technology and service delivery. Our software is CE-marked in accordance with the MDR, and we process all data within the EU to ensure strict compliance with the GDPR. We assist all customers with onboarding, and our support team is available seven days a week to provide any assistance you may require.</p>",
      },
      imageOrVideo:
        "/uploads/Curoflow-practice-management-system-used-at-a-clinic.webp",
      link: "/en/our-solution/technology-and-support-in-practice-management-software",
      btnText: {
        se: "Upptäck vår teknik & support",
        en: "Discover our technology & support",
      },
      edit: 7,
      alt: "Curoflow practice management system is used at a clinic for patient case administration"

    },
  ],
  titleBelowFirstSlider: {
    en: "Let's have a lunch?",
    se: "Får vi bjuda dig på lunch?",
  },
  imageBelowFirstSlider:
    "/uploads/Curoflow_telemedicine_plaform_is_white_label_on_both_computer_and_mobile_app.jpg",
  titleAboveSecondSlider: {
    en: "Modern functions for digital healthcare",
    se: "Moderna funktioner för digifysisk vård",
  },
  descriptionAboveSecondSlider: {
    en: "<h6>Imagine a world where administrative tasks no longer consume all your valuable time, and you can provide patient-centred care that exceeds expectations. With Curoflow, you can create a seamless flow in patient care, ensuring patient safety and data security. Streamline your processes, improve patient outcomes, and experience the power of Curoflow's revolutionary solutions that put healthcare back in your hands. Join the revolution and discover the possibilities of the Curoflow practice management software today.</h6>",
    se: "<h6>F&ouml;rest&auml;ll dig en v&auml;rld d&auml;r administrativa uppgifter inte l&auml;ngre tar upp all er v&auml;rdefulla tid och d&auml;r ni kan erbjuda en patientcentrerad v&aring;rd som &ouml;vertr&auml;ffar f&ouml;rv&auml;ntningarna. Med Curoflow kan ni skapa s&ouml;ml&ouml;sa v&aring;rdfl&ouml;den som garanterar s&aring;v&auml;l patient- som datas&auml;kerhet. Effektivisera era processer, f&ouml;rb&auml;ttra patientresultaten och upplev kraften i Curoflows revolutionerande l&ouml;sningar som m&ouml;jligg&ouml;r en effektivare och mer patientn&auml;ra v&aring;rd.&nbsp;</h6>",
  },
  descriptionBelowSecondSlider: {
    se: "<h6>Upplev kraften hos Curoflow! S&ouml;ml&ouml;s digifysisk v&aring;rd genom f&ouml;renklade arbetsfl&ouml;den, anpassningsbara l&ouml;sningar, f&ouml;rb&auml;ttrade patientupplevelser, en integrerad plattform och en fram&aring;triktad strategi</h6>",
    en: "<h6>Experience the power of Curoflow for seamless practice management, featuring simplified workflows, customisable solutions, enhanced patient experiences, seamless integrations, and a future-focused approach.</h6>",
  },
  imageBelowSeconSlider: "/uploads/imageClient.png",
  titleBelowSecondSlider: {
    en: "Why Curoflow practice management software?",
    se: "Varför Curoflow digital vårdplattform?",
  },
  section4: {
    text1: {
      se: "<p>Hela plattformen, inklusive videotj&auml;nsten, behandlar all data inom Sverige f&ouml;r strikt efterlevnad av GDPR och PDL</p>",
      en: '<p><span style="white-space-collapse: preserve;">The entire software, including the video service, is developed and hosted within the EU for strict GDPR compliance</span></p>',
    },
    text2: {
      se: "<p>Curoflow &auml;r utvecklad av sjukv&aring;rdspersonal f&ouml;r att spara tid och ge smidigare patientfl&ouml;den</p>",
      en: '<p><span style="white-space-collapse: preserve;">Curoflow is developed by healthcare professionals to save time and ensure smooth patient flow</span></p>',
    },
    text3: {
      se: "<p>Integration med journalsystem f&ouml;r online-bokningar och smidig export av journalanteckningar</p>",
      en: '<p><span style="white-space-collapse: preserve;">Integration with electronic health records for online bookings and simple export of clinical notes</span></p>',
    },
    text4: {
      se: "<p>Monitorering av kroniska tillst&aring;nd, med v&auml;rdefull statistik, hj&auml;lper er att fatta datadrivna beslut</p>",
      en: '<p><span style="white-space-collapse: preserve;">State-of-the-art chronic condition monitoring helping you make data-driven decisions</span></p>',
    },
    text5: {
      se: "<p>Design och arbetsfl&ouml;den kan skr&auml;ddarsys f&ouml;r att passa v&aring;rdgivare av alla typer och storlekar</p>",
      en: '<p><span style="white-space-collapse: preserve;">Design and workflows can be customised to suit healthcare providers of all types and sizes</span></p>',
    },
    text6: {
      se: "<p>Sj&auml;lvbetj&auml;ning f&ouml;r patienter, automatisering och dynamisk kommunikation g&ouml;r att ni kan hj&auml;lpa fler patienter</p>",
      en: '<p><span style="white-space-collapse: preserve;">Patient self-service, automation and dynamic communication enable you to help more patients</span></p>',
    },
    text7: {
      se: "<p>Era patienter kan enkelt följa och hantera sina vårdärenden genom er webb- eller appbaserade patientportal</p>",
      en: '<p><span style="white-space-collapse: preserve;">Your patients can easily monitor and manage their care through your online patient portal</span></p>',
    },
    text8: {
      se: "<p>St&auml;rk varum&auml;rket och patientlojaliteten med djupg&aring;ende white label-funktionalitet</p>",
      en: '<p><span style="white-space-collapse: preserve;">Boost brand and patient loyalty with extensive white-label functionality - It’s all about you!</span></p>',
    },
    image1: "/uploads/Secure_&_GDPR_compliant.svg",
    image2: "/uploads/Save_time_in_your_healthcare.svg",
    image3: "/uploads/Online_bookings_&_journal_notes.svg",
    image4: "/uploads/Monitoring_of_chronic_conditions.svg",
    image5: "/uploads/Design_&_customization.svg",
    image6: "/uploads/Help_more_patients.svg",
    image7: "/uploads/mobil_app_patient_portal.svg",
    image8: "/uploads/white_label_functions.svg",
  },
  section8: {
    title: {
      se: "<h1>Anpassad f&ouml;r just er v&aring;rdverksamhet</h1>",
      en: "<h1>Customised to fit your healthcare practice</h1>",
    },
    description: {
      se: "<h6>Curoflow plattform &auml;r anpassningsbar och skalbar f&ouml;r att m&ouml;ta behoven hos olika v&aring;rdverksamheter, fr&aring;n enskilda kliniker till hela kommuner och regioner. Upplev Curoflows flexibilitet och styrka n&auml;r plattformen smidigt utformas efter er unika v&aring;rdmilj&ouml; med optimerade arbetsfl&ouml;den och f&ouml;rb&auml;ttrad patientv&aring;rd.</h6>",

      en: "<h6>The Curoflow practice management software is designed to seamlessly adapt and scale to meet the needs of diverse healthcare organisations, ranging from individual GP practices to large healthcare organisations. Experience the flexibility and power of Curoflow as it effortlessly caters to your unique healthcare setting, ensuring optimised workflows and enhanced patient care.</h6>",
    },
  },
  thirdSliderItems: [
    {
      imageOrVideo:
        "/uploads/Aleris-mobile-app-for-digital-psychiatry-uses-Curoflow-for-practice-management.webp",
      imageLogo: "/uploads/Aleris_use_Curoflow_telemedicine_software_for_digital_patient_meetings.png",
      description: {
        se: '<p>"Curoflow har gett oss ett smidigare patientflöde och gjort det möjligt att ha all digital patientinteraktion i ett och samma system. Genom Curoflows plattform kan patienterna enkelt skicka in sin egenremiss och när som helst få stöd av oss via chatt och videomöten. Det har också varit enkelt att anpassa frågeformulär och flöden. Vi är mycket glada över att både vår personal och våra patienter tycker att verktyget är praktiskt och enkelt."</p>\n<p>&nbsp;</p>\n<p>Lina Anderhell Öberg<br />Enhetschef, Aleris Digital Psykiatri</p>',
        en: '<p>"Curoflow has given us a smoother patient flow and made it possible to have all digital patient interaction in one system. Through Curoflow, patients can easily submit their self-referrals and get support from us at any time via chat and video consultations. It has also been easy to customise questionnaires and flows. We are delighted that both our staff and our patients find the tool practical and easy."</p>\n<p>&nbsp;</p>\n<p>Lina Anderhell Öberg<br />Unit Manager, Aleris Digital Psychiatry</p>',
      },
      edit: 0,
      alt: "Patient using Aleris mobile app for digital psychiatry with Curoflow practice management software"
    },
    {
      imageOrVideo:
        "/uploads/Accesscare-using-Curoflow-telemedicine-platform-for-home-healthcare.webp",
      imageLogo: "/uploads/AccessCare_use_Curoflow_telemedicine.png",
      description: {
        se: '<h6>"Curoflow är en trygghet för våra patienter då de enkelt kan kontakta oss när som helst. Vi bedriver avancerad sjukvård i hemmet och Curoflow ger oss ett mycket bra sätt att möta våra patienter även på distans. På det sättet ökar vi tillgängligheten samtidigt som vi även underlättar för vår personal i sitt dagliga arbete. Såväl formulärverktyg, gränssnitt, chatt och video har varit uppskattade funktioner som framtidssäkrar vår verksamhet och ser till att vi ligger i framkant."</h6>\n<p>&nbsp;</p>\n<p>Arja Autere<br />Verksamhetschef, ASiH AccessCare</p>',


        en: '<p>"Curoflow provides peace of mind for our patients as they can easily reach us at any time. We deliver advanced healthcare services to patients in their homes, and Curoflow offers us an excellent way to engage with our patients, even remotely. This enhances accessibility while also streamlining our staff\'s daily work. The interface, with its medical forms, chat, and video functionalities have been well-received features that future-proof our operations and ensure that we remain at the forefront."</p>\n<p>&nbsp;</p>\n<p>Arja Autere<br />Operations Manager, ASiH AccessCare</p>',
      },
      edit: 1,
      alt: "Accesscare nurses provides advanced in-home healthcare with Curoflow telemedicine platform"
    },
    // {
    //   imageOrVideo:
    //     "/uploads/Migränhjälpen_telemedicine_mobile_app_by_Curoflow_software_provider.jpg",
    //   imageLogo: "/uploads/Migränhjälpen_use_Curoflow_telemedicine.png",
    //   description: {
    //     en: '<h3>“Curoflow has a well-functioning and straightforward platform. They are also very responsive and quick to make improvements to the platform. As a customer, you feel that they are genuinely listening to you.”</h3>\n<p>&nbsp;</p>\n<p>Mattias Bodin<br />CEO, Migr&auml;nhj&auml;lpen</p>',


    //     se: '<h3>"Curoflow har en bra och tydlig plattform. De &auml;r ocks&aring; v&auml;ldigt lyh&ouml;rda och snabba med att komma med f&ouml;rb&auml;ttringar i plattformen, s&aring; som kund k&auml;nner man att de verkligen lyssnar p&aring; en."</h3>\n<p>Mattias Bodin</p>\n<p>VD, Migr&auml;nhj&auml;lpen</p>',
    //   },
    //   edit: 2,
    // },
    {
      imageOrVideo:
        "/uploads/IVF-doctor-at-Gynhälsan-uses-Curoflow-for-patient-communication.webp",
      imageLogo: "/uploads/Gynhälsan_Curoflow_customer.png",
      description: {
        se: '<h3>"Curoflows plattform ger oss ett fantastiskt smidigt och enkelt sätt att kommunicera med våra patienter."</h3>\n<p>Marianne Holmgren Windh</p>\n<p>Gynekolog, Gynh&auml;lsan IVF</p>',
        en: "<h3>“Curoflow practice management software gives us an incredibly flexible and easy way to communicate with our patients”</h3>\n<p>&nbsp;</p>\n<p>Marianne Holmgren Windh<br />Gynecologist, Gynh&auml;lsan IVF</p>",
      },
      edit: 3,
      alt: "IVF doctors at Gynhälsan uses Curoflow for digital patient communication through chat and video"
    },
    {
      imageOrVideo:
        "/uploads/Branded-digital-patient-portal-at-Ekenhälsan.webp",
      edit: 4,
      imageLogo: "/uploads/Ekenhälsan_use_Curoflow_telemedicine_software.png",
      description: {
        se: '<p class="MsoNormal">&ldquo;Curoflow har minskat administrationen och v&aring;ra telefonk&ouml;er vilket &auml;r ett stort lyft f&ouml;r b&aring;de v&aring;r personal och v&aring;ra patienter! Genom mobilappen kan patienterna chatta med oss, f&aring; provsvar, f&ouml;rnya recept m.m och v&aring;r personal hanterar inkommande &auml;renden n&auml;r det passar dem. Curoflow &auml;r white label p&aring; riktigt och smidig att anpassa vad g&auml;ller b&aring;de funktion och utseende. Patienternas funktioner i appen har vi format efter v&aring;ra fl&ouml;den och hela gr&auml;nssnittet &auml;r designat utefter hur vi vill se ut. Det k&auml;nns &auml;ven bra att Curoflow p&aring; riktigt lever upp till GDPR och inte anv&auml;nder sig av utomeuropeiska molntj&auml;nster som riskerar v&aring;ra patienters integritet."</p>\n<p>&nbsp;</p>\n<p>Johan Meisel</p>\n<p>IT-ansvarig, Ekenh&auml;lsan</p>',



        en: "<p>\"Curoflow has reduced administration and our telephone queues, which is a great improvement for both our staff and our patients! Through the mobile app, patients can chat with us, get test results, renew prescriptions etc. and our staff manage incoming cases at a time that suits them. Curoflow is truly white-label and easy to customise in terms of both functionality and appearance. We have customised the patients' functionalities in the app according to our flows and the entire interface is designed to reflect our brand identity. It's also reassuring to know that Curoflow truly complies with GDPR and does not use overseas cloud services that risk our patients' privacy.\"</p>\n<p>&nbsp;</p>\n<p>Johan Meisel</p>\n<p>IT Manager, Ekenh&auml;lsan</p>",
      },
      alt: "Patient uses Ekenhälsans branded digital patient portal for scheduling healthcare appointments"
    },
    {
      imageOrVideo:
        "/uploads/Gavle-lakarmottagning-uses-Curoflow-practice-management-software.webp",
      edit: 4,
      imageLogo: "/uploads/Gävle_läkarmottagning_uses_curoflow_telemedicine_platform.webp",
      description: {
        se: '<p class="MsoNormal">\"Curoflow har förbättrat vårt patientflöde och gör det möjligt för våra patienter att boka sin tid när som helst på dygnet. Med Curoflows plattform kan patienter enkelt se vilka tjänster och tider som erbjuds, de kan hantera sina bokningar och få påminnelser om sina besök. Vi och våra patienter är mycket nöjda över detta då det ökar både tillgängligheten och effektiviteten i vår verksamhet. Det har också minskat den administrativa belastningen på vår personal, vilket gör att vi kan fokusera mer på att ge kvalitativ vård.\"</p>\n<p>&nbsp;</p>\n<p>Henrik Wrethling</p>\n<p>Verksamhetschef och läkare i Allmänmedicin, Gävle Läkarmottagning</p>',


        en: "<p>\“Curoflow has improved our patient flow, allowing patients to book their appointments at any time, day or night. Using Curoflow's platform, patients can easily browse available services and appointment slots, manage their bookings, and receive timely reminders for their visits. Our staff and patients are very pleased with this, as it enhances both the accessibility and efficiency of our operations. It has also reduced the administrative burden, allowing us to focus more on delivering high-quality care.”</p> \n<p>&nbsp;</p>\n<p>Henrik Wrethling</p>\n<p>Head of Operations and General Practitioner, Gävle Läkarmottagning</p>",
      },
      alt: "Gävle läkarmottagning uses Curoflow practice management system"
    },
  ],



  lastSliderITems: [
    {
      tabname: { se: "Primärvård", en: "General practitioner" },
      title: { se: "Primärvårdsmottagning", en: "General practitioner (GP)" },
      description: {
        se: "<p>&Ouml;ka bekv&auml;mligheten och spara tid f&ouml;r era patienter genom att l&aring;ta dem n&aring; er v&aring;rdmottagning online. Via er digitala portal kan patienterna f&aring; information, s&ouml;ka v&aring;rd, triageras, redog&ouml;ra f&ouml;r sina besv&auml;r och boka tider. Det frig&ouml;r tid f&ouml;r er och till patienter som beh&ouml;ver bes&ouml;ka er fysiskt. Curoflow erbjuder en m&auml;ngd funktioner och integrationer f&ouml;r att underl&auml;tta er och era patienters vardag! &nbsp;</p>",



        en: "<p>Curoflow is the ultimate copilot for UK-based general practitioners, streamlining every aspect of a modern GP clinic. The platform features robust tools for scheduling, billing, and patient communication via chat and video, effortlessly integrating with EHR systems. The white-label software is customisable, adapting to your clinic’s needs while enhancing your brand identity across your patient-facing web portal and mobile app.</p>",



      },
      imageOrVideo:
        "/uploads/General-practitioners-uses-Curoflow-medical-billing-software.webp",
      link: "/en/we-assist/practice-management-software-for-general-practitioners",
      btnText: {
        se: "Upptäck Curoflow för primärvården",
        en: "Discover Curoflow for GPs",
      },
      edit: 0,
      alt: "General practitioners in the UK uses Curoflow medical billing software"
    },
    {
      tabname: { en: "Specialist centre", se: "Specialistvård" },
      title: { en: "Specialist centre", se: "Specialistvård" },
      description: {
        en: "<p>With Curoflow, you can customise the platform to suit your specialist centre’s care processes and patient flows. Connect with patients online, in person, or through hybrid options—whether drop-in or pre-booked. Use chat or video for digital communication, and tailor forms for pre-visit or between-visit assessments. As a white-label solution, Curoflow also lets you design your digital patient portal to align with your identity, for a fully branded digital patient experience.</p>",
        se: "<p>Curoflow &auml;r white label och anpassas efter varje mottagnings specialitet, unika fl&ouml;den och behov. Ni styr vilka funktioner som patienterna f&aring;r tillg&aring;ng till i den digitala portalen. De dynamiska formul&auml;ren f&ouml;r anamnesupptagning skr&auml;ddarsys f&ouml;r just er mottagning. Med kalendrar och patientdagb&ouml;cker f&ouml;r uppf&ouml;ljning och st&ouml;d vid l&auml;ngre behandlingar &auml;r Curoflow perfekt f&ouml;r patienter med kroniska tillst&aring;nd.</p>",
      },
      imageOrVideo:
        "/uploads/Doctor-in-specialised-care-centre.webp",
      link: "/en/we-assist/practice-management-software-for-specialist-centres",
      btnText: {
        en: "Discover Curoflow for specialist centres",
        se: "Upptäck Curoflow för specialistvård",
      },
      edit: 1,
      alt: "Doctor in specialised care centre using Curoflow for patient communication"
    },
    {
      tabname: { se: "Fertilitetsvård", en: "Mental health" },
      title: { se: "Fertilitetsvård", en: "Mental health" },
      description: {
        se: "<p> Curoflow underlättar kommunikationen under fertilitetsutredningar och behandlingar. Väntetider förkortas och antalet uteblivna besök minskar. Plattformens dagbok är mycket uppskattad för planering, uppföljning och monitorering av fertilitetsbehandlingar. För IVF-patienter med en mängd aktiviteter att hålla reda på är Curoflow ett pålitligt stöd.</p>",


        en: "<p>Digital and hybrid care options are the future of mental healthcare. Curoflow all-in-one practice management software optimises your clinic operations while enhancing patient engagement, empowering mental health clinics to deliver frictionless, accessible hybrid care. Conduct multi-party video consultations with the telehealth software, monitor patient progress remotely using our patient health log, and deliver self-help programmes through our platform.</p>",
      },
      imageOrVideo:
        "/uploads/Mental-health-provider-taking-notes-in-patient-meeting.webp",
      link: "/en/we-assist/practice-management-software-for-mental-health",
      btnText: {
        se: "Upptäck Curoflow för fertilitetsvård",
        en: "Discover Curoflow for mental health providers",
      },
      edit: 2,
      alt: "Mental health provider uses Curoflow to take notes in Curoflow during patient meeting"
    },
    {
      tabname: { se: "Företagshälsovård", en: "Women’s health" },
      title: { se: "Företagshälsovård", en: "Women’s health" },
      description: {
        se: "<p>Erbjud tillg&auml;nglig och flexibel f&ouml;retagsh&auml;lsov&aring;rd med Curoflow! Plattformen ger er en direkt digital kanal till era klienter med chatt, videom&ouml;ten, schemal&auml;ggning av m&ouml;ten och mycket mer. Genom Curoflow kan ni enkelt distribuera instruktionsvideor och bjuda in anst&auml;llda till fysiska tester och h&auml;lsounders&ouml;kningar. Curoflow reducerar &auml;ven restiden och g&ouml;r det m&ouml;jligt f&ouml;r anst&auml;llda att kommunicera hemifr&aring;n n&auml;r de &auml;r sjukskrivna.</p>",


        en: "<p>Tailor the Curoflow platform to your women’s health or reproductive care clinic. Provide patients with a calendar to navigate each step of their journey, with automated notifications and reminders, and create a safe space for interaction using the telemedicine software. Customise forms and questionnaires for pre-visit and between-visit assessments and select the health metrics patients log in their journals to enable flexible, remote patient monitoring.</p>",
      },
      imageOrVideo:
        "/uploads/Womens-health-patient-using-curoflow-practice-management-system.webp",
      link: "/en/we-assist/practice-management-software-for-womens-health",
      btnText: {
        se: "Upptäck Curoflow för företagshälsovård",
        en: "Discover Curoflow for women’s health",
      },
      edit: 3,
      alt: "Womens health patient using digital patient portal in app with Curoflow practice management software"
    },
    {
      tabname: { se: "Elevhälsa", en: "Preventive health" },
      title: { se: "Elevhälsa", en: "Preventive health" },
      description: {
        se: "<p>M&ouml;t eleven p&aring; elevens villkor. Genom app eller webbgr&auml;nssnitt m&ouml;jligg&ouml;r Curoflow flexibel kommunikation och &ouml;kar tillg&auml;ngligheten till elevh&auml;lsan f&ouml;r b&aring;de elever och deras v&aring;rdnadshavare. Dagboksfunktionen m&ouml;jligg&ouml;r h&auml;lsomonitorering av elever och ger strukturerad data f&ouml;r statistik och resursplanering. Anv&auml;nd Curoflow digital v&aring;rdplattform f&ouml;r att dela p&aring; personalresurser och f&ouml;rb&auml;ttra elevernas tillg&aring;ng till god v&aring;rd!</p>",


        en: "<p>With our practice management software, you have the tools needed to make healthcare accessible while keeping patients actively engaged in their health journeys. Track patient health data and manage chronic conditions using the remote monitoring module, provide your patients with educational programmes and self-help resources through your digital patient portal, and give them access to the patient calendar to navigate each step of their journey, with automated notifications and reminders.</p>",
      },
      imageOrVideo:
        "/uploads/Calendar-view-in-Curoflow-mobile-app.webp",
      link: "/en/we-assist/practice-management-software-for-preventive-health",
      btnText: {
        se: "Upptäck Curoflow för elevhälsa",
        en: "Discover Curoflow for preventive health",
      },
      edit: 4,
      alt: "Patient using the calendar view in Curoflow mobile app"
    },
    {
      tabname: { se: "Hemsjukvård", en: "Private healthcare" },
      title: { se: "Hemsjukvård", en: "Private healthcare" },
      description: {
        se: "<p>Patienter som f&aring;r sjukv&aring;rd i hemmet kan genom Curoflow enkelt h&aring;lla kontakten med er via sin mobiltelefon. F&ouml;rutom s&auml;ker chatt och videosamtal d&auml;r ni p&aring; ett s&auml;kert s&auml;tt kan bjuda in externa personer, m&ouml;jligg&ouml;r &auml;ven plattformen monitorering av patienterna p&aring; distans. Denna v&auml;rdefulla information underl&auml;ttar f&ouml;rberedelserna inf&ouml;r n&auml;sta fysiska bes&ouml;k och minimerar on&ouml;diga resor.</p>",


        en: "<p>Many private clinics struggle with heavy administration, manual tasks, and inflexible software that hinders customisation and clinic growth. Curoflow is built to grow alongside your practice, providing streamlined, future-proof operations and a competitive edge. Reduce administration through self-service appointment scheduling and manage billing with built-in payment processing that allows direct payments through your mobile app, boosting cash flow.</p>",
      },
      imageOrVideo:
        "/uploads/Nurse-in-private-clinic-using-Curoflow-digital-healthcare-platform.webp",
      link: "/en/we-assist/practice-management-software-for-private-healthcare",
      btnText: {
        se: "Upptäck Curoflow för hemsjukvård",
        en: "Discover Curoflow for private healthcare",
      },
      edit: 5,
      alt: "Nurse in a private healthcare clinic using Curoflow digital healthcare platform"
    },
    {
      tabname: { se: "Omsorg", en: "Corporate healthcare" },
      title: { se: "Omsorg", en: "Corporate healthcare" },
      description: {
        se: "<p>Curoflow effektiviserar kommunikation och &auml;rendehantering, oavsett om det g&auml;ller socialtj&auml;nst, barnomsorg, hemtj&auml;nst eller s&auml;rskilt boende. Digitala ing&aring;ngar f&ouml;rb&auml;ttrar tillg&auml;ngligheten och med GDPR-s&auml;ker chatt/video f&ouml;renklas dialogen b&aring;de interprofessionellt och med era klienter. I Curoflow plattform har ni m&ouml;jlighet att designa b&aring;de utseende och funktionalitet f&ouml;r varje enhet i er organisation.</p>",


        en: "<p>Offer your client’s employees digital access to corporate healthcare through your web portal or mobile app. With corporate healthcare at their fingertips, employees can schedule and reschedule health checks, access video consultations with specialists, and receive shared forms, videos, or documents via chat. Clinicians can also track employee health metrics, individually and at a group level.</p>",
      },
      imageOrVideo:
        "/uploads/Emoployee-using-Curoflow-to-access-corporate-healthcare.webp",
      link: "/en/we-assist/practice-management-software-for-corporate-healthcare",
      btnText: {
        se: "Upptäck Curoflow för omsorg",
        en: "Discover Curoflow for corporate healthcare",
      },
      edit: 6,
      alt: "Employee using Curoflow to access his corporate healthcare"
    },
    {
      tabname: { se: "Region", en: "Community care" },
      title: { se: "Region", en: "Community care" },
      description: {
        se: "<p>Curoflow l&aring;ter er anv&auml;nda en enhetlig digital kommunikationsplattform p&aring; alla enheter i er region. Ni best&auml;mmer sj&auml;lva hur ni vill strukturera avdelningar, kliniker, appar och webbgr&auml;nssnitt f&ouml;r era patienters digitala ing&aring;ngar. Eftersom Curoflow erbjuds som white label, kan patientportalerna utformas efter regionens olika profiler, och varje enhet kan sj&auml;lva best&auml;mma hur funktionerna ska skr&auml;ddarsys f&ouml;r just deras verksamhet.</p>",


        en: "<p>Your community care needs flexible software that supports a wide range of services. From home care and chronic condition management to child and sexual health, Curoflow adapts seamlessly to your unique care processes and patient journeys. It also promotes collaboration across the healthcare ecosystem, enabling virtual multidisciplinary team meetings and ensuring smooth information flow among community nurses, therapists, GPs, and hospitals.</p>",
      },
      imageOrVideo:
        "/uploads/Home-care-patient-using-Curoflow-practice-management-software.webp",
      link: "/en/we-assist/practice-management-software-for-community-care",
      btnText: {
        se: "Upptäck Curoflow för er region",
        en: "Discover Curoflow for community care",
      },
      edit: 7,
      alt: "Home care patient using Curoflow practice management software for digital patient communication"
    },
    {
      tabname: { se: "Kommun", en: "Healthcare organisation" },
      title: { se: "Kommun", en: "Healthcare organisation" },
      description: {
        se: "<p>F&ouml;rb&auml;ttra tillg&auml;ngligheten och kommunikationen i din kommun! Curoflow &auml;r utformat f&ouml;r att st&ouml;dja medborgar- och patienthantering inom ett brett spektrum av tj&auml;nster. Det m&ouml;jligg&ouml;r att ni kan implementera en enhetlig digital kommunikationsplattform hos alla enheter i er kommun. Genom s&auml;kra inlogg och databehandling i Sverige m&ouml;jligg&ouml;r v&aring;r videotj&auml;nst s&auml;kra flerpartssamtal som strikt f&ouml;ljer GDPR.</p>",


        en: "<p>Curoflow allows you to implement a unified communication platform across all units and locations within your large organisation. The scalable practice management software places no limits on the number of clinicians or patients and gives your organisation full control over the structure of your departments, units, or clinics. Curoflow combines the flexibility to meet the unique needs of each clinic with frictionless functionality across your entire organisation.</p>",
      },
      imageOrVideo:
        "/uploads/Clinic-administrator-using-Curoflow-for-digital-patient-communication.webp",
      link: "/en/we-assist/practice-management-software-for-healthcare-organisations",
      btnText: {
        se: "Upptäck Curoflow för er kommun",
        en: "Discover Curoflow for healthcare organisations",
      },
      edit: 8,
      alt: "Clinic administrator using Curoflow for digital patient communication"
    },
    {
      tabname: { se: "Kommun", en: "NHS" },
      title: { se: "Kommun", en: "NHS" },
      description: {
        se: "<p>F&ouml;rb&auml;ttra tillg&auml;ngligheten och kommunikationen i din kommun! Curoflow &auml;r utformat f&ouml;r att st&ouml;dja medborgar- och patienthantering inom ett brett spektrum av tj&auml;nster. Det m&ouml;jligg&ouml;r att ni kan implementera en enhetlig digital kommunikationsplattform hos alla enheter i er kommun. Genom s&auml;kra inlogg och databehandling i Sverige m&ouml;jligg&ouml;r v&aring;r videotj&auml;nst s&auml;kra flerpartssamtal som strikt f&ouml;ljer GDPR.</p>",


        en: "<p>Curoflow supports NHS organisations with GDPR-compliant digital solutions to enhance care coordination, reduce administrative workload, and improve the patient experience. Optimise patient intake across your NHS organisation with intelligent triage, minimise administration with the self-service scheduling system, conduct video consultations with our telehealth software, and design your digital patient portal for a branded patient experience.</p>",
      },
      imageOrVideo:
        "/uploads/NHS-professional-uses-Curoflow-for-GDPR-compliant-clinic-management.webp",
      link: "/en/we-assist/practice-management-software-for-NHS",
      btnText: {
        se: "Upptäck Curoflow för er kommun",
        en: "Discover Curoflow for NHS organisations",
      },
      edit: 9,
      alt: "NHS professional uses Curoflow for GDPR-compliant clinic management"
    },
  ],
  section7: {
    title: {
      se: "<h1>V&aring;ra kunder ber&auml;ttar</h1>",
      en: "<h1>Voices of success&nbsp;</h1>",
    },
    description: {
      se: "<h6>H&ouml;r v&aring;ra kunder ber&auml;tta om hur Curoflow har lyft deras verksamheter. Uppt&auml;ck hur v&aring;r digitala v&aring;rdplattform har transformerat deras v&aring;rdmottagningar, f&ouml;rb&auml;ttrat patientv&aring;rden och &ouml;kat effektiviteten. G&ouml;r som v&aring;ra kunder och uppt&auml;ck potentialen i digifysisk v&aring;rd genom Curoflow.</h6>",

      en: "<h6>Hear from our customers: real stories of success with the Curoflow practice management software. Discover how Curoflow has transformed their practices, improved patient care, and enhanced operational efficiency. Join them on their journey to unlock the potential of digital healthcare.</h6>",
    },
  },
  section6: {
    description1: {
      se: "<p>Sparad tid p&aring; administrativa uppgifter</p>",
      en: "<p>Time saved on administrative tasks&nbsp;</p>",
    },
    description2: {
      se: "<p>Fler patient&auml;renden per v&aring;rdpersonal</p>",
      en: "<p>More patient cases per healthcare professional&nbsp;</p>",
    },
    description3: { se: "<p>ROI</p>", en: "<p>ROI&nbsp;</p>" },
    title: {
      se: "<h1>Effektivisera er v&aring;rd genom Curoflow</h1>",
      en: "<h1>The impact of Curoflow in your care&nbsp;</h1>",
    },
    subtitle: {
      se: "<h6>Curoflow v&aring;rdplattform optimerar patienthanteringen och f&ouml;rb&auml;ttrar resultaten i er verksamhet</h6>",
      en: "<h6>Reap the business benefits enabled by the Curoflow practice management software</h6>",
    },
  },
  section5: {
    title: {
      se: "<h1>Flyt in i framtiden:</h1>\n<h1>Er digitala plattform f&ouml;r digifysisk v&aring;rd</h1>",
      en: "<h1>Flow into the future:</h1>\n<h1>Your digital healthcare platform</h1>",
    },
    subtitle: {
      se: "<h6>Skapa framtidens sjukvård med Curoflow: Starta er egen digifysiska vårdmottagning och hantera era patienter online. Vi kan digital vård, teknik och datasäkerhet vilket ger er friheten att fokusera på er verksamhet och era patienter. Låt oss tillsammans utforma en patientcentrerad vård för er verksamhet genom förbättrad tillgänglighet, smidiga möten, användarvänliga gränssnitt och automatiserade processer. Upptäck möjligheterna med Curoflow vårdplattform.</h6>",
      en: "<h6>Unleash the future of healthcare with Curoflow: build your digital practice and connect with patients online. We embrace digital health, technology, and data security, empowering you to focus on your practice and patients. Let's redefine patient-centric care through enhanced accessibility, streamlined interactions, intuitive interfaces, and automated processes. Discover the possibilities of Curoflow today.</h6>",
    },
    pdfswe: {
      fileName: "Curoflow-plattform-for-digifysisk-vard-&-halsa.pdf",
      file: "/uploads/Curoflow-plattform-for-digifysisk-vard-&-halsa.pdf",
    },
    titleAndSubtitle: {
      se: "<h3>Frig&ouml;r tid och f&ouml;rb&auml;ttra arbetsmilj&ouml;n f&ouml;r er personal</h3>\n<p>Stärk er vårdpersonal i deras dagliga arbete! Självbetjäning för patienter, asynkron digital kommunikation, och automatiserade processer frigör mycket tid till att ge en god vård. Förkortade väntetider och färre personer i era lokaler minskar även stressen för er vårdpersonal. Omfamna distansarbete, optimera personalresurser och utöka er räckvidd så ni kan hjälpa patienter från större geografiska områden. Upptäck vad Curoflow kan göra för er verksamhet!</p>",
      en: "<h3>Free up time and enhance the working environment for your staff</h3>\n<p>Unleash the power of Curoflow: empowering healthcare providers with self-service patient management, asynchronous digital communication, and automated processes, saving valuable time for quality care. Reduced wait times and decreased crowding alleviate stress on healthcare staff. Embrace remote work, optimise staff resources, and expand your reach to serve patients from a larger geographical area. Experience the Curoflow practice management software and revolutionise your practice today!</p>",
    },
    image1:
      "/uploads/Nurse-handles-incoming-patient-cases-in-Curoflow.webp",
    titleAndSubtitle1: {
      se: "<h3>G&ouml;r v&aring;rden l&auml;ttare och mer tillg&auml;nglig f&ouml;r era patienter</h3>\n<p>Gör vården smidigare för era patienter genom att låta dem interagera med er mottagning när och var som helst, utan att behöva vänta i telefonsamtal eller väntrum. Med Curoflow kan patienterna enkelt skapa, följa och hantera sina vårdärenden digitalt. Plattformen möjliggör privata vårdmöten under hektiska vardagar och att patienter snabbt kan få hjälp med sina besvär. Eftersom fysisk kapacitet frigörs av det digitala kommer era patienter även uppleva kortare ledtider för mer avancerade bedömningar och behandlingar.</p>",

      en: "<h3>Make healthcare faster and more accessible for your patients</h3>\n<p>Empower your patients by allowing them to interact with your healthcare practice anytime and anywhere, without having to wait on the phone or in a waiting room. With Curoflow, patients can easily create, track, and manage their healthcare cases digitally. The practice management software facilitates private healthcare appointments during busy weekdays and allows patients to quickly receive assistance. As physical capacity is freed up by the digital system, your patients will also experience shorter wait times for more advanced treatments. Provide your patients with the convenience they deserve! </p>",
    },
    image2:
      "/uploads/Patient-using-healthcare-app-with-Curoflow-practice-management-software.webp",
    pdfen: {
      fileName:
        "Curoflow-Practice-Management-System-for-UK-healthcare-providers.pdf",
      file: "/uploads/Curoflow-Practice-Management-System-for-UK-healthcare-providers.pdf",
    },
  },
  section9: {
    title: {
      se: "<h2>&Auml;r du nyfiken p&aring; Curoflow v&aring;rdplattform?</h2>",
      en: "<h2>Would you like a demo of our practice management software?</h2>",
    },
    description: {
      se: "<p>Undrar du hur den digitala v&aring;rdplattformen ser ut och hur den hade fungerat i ert dagliga arbete? Vill du prata med en leverant&ouml;r som kommer fr&aring;n v&aring;rden och f&ouml;rst&aring;r era behov? Vi diskuterar g&auml;rna er verksamhet och hur Curoflows olika funktioner skulle kunna effektivisera den. Skicka ett meddelande till oss s&aring; bokar vi in en demo av plattformen och ber&auml;ttar mer!&nbsp;</p>",

      en: "<p>Are you curious about how our practice management system works and how it could fit into your daily operations? Are you ready to switch to a software provider with a healthcare background, who truly understands your needs? We’d be happy to discuss your business and explore how Curoflow’s various features could help streamline your processes. You could be treating patients digitally within just a few weeks. <br/> <br/>  <p> </p>Send us a message, and we’ll get in touch! </p>",
    },
  },
};
